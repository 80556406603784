import React from 'react';

const HelpContentStatic = () => {
  return (
    <div className="awsui-util-m-l" data-awsui-column-layout-root="true">
      <div>
        <h3>Need Help?</h3>
        <ul>
          <li><a href="mailto: aws-scm-ux@amazon.com">Contact Us</a></li>
          <li><a href="https://t.corp.amazon.com/create/templates/2dfa697f-c937-41e7-98f2-b4b2c8736510" target="_blank" rel="noreferrer">Report a Bug</a></li>
          <li><a href="https://w.amazon.com/bin/view/AWS_SCM/UXTeam/Argo" target="_blank" rel="noreferrer">About Argo</a></li>
        </ul>
      </div>
    </div>
  );
}

export default HelpContentStatic;