
// All the strings that are displayed to user in the screens

export const USER_DISPLAY_STRINGS = {
  deprecate: 'This page is on a path for deprecation and will be moved to a new URL. Please update your bookmarks accordingly by ',
  mainTitle: 'Welcome to the Infra Portal',
  helpText: 'To access your tools, please select an option from the navigation bar on the left.',
  visitingNewURL: 'visiting the new URL',
  aplmText: 'APLM',
  searchResultPageTitle: 'Search',
  comingSoon: 'coming soon',
  comingSoonTitle: 'Page coming soon',
  comingSoonContent: 'Thanks for testing',
  pageNotExistText: 'The page does not exist.',
  nonePicklistLabel: '-- None --',
  breadcrumbs: {
    home: 'Home',
    search: 'Search',
    comingSoon: 'coming soon',
  },
  anyConcerns: 'For questions or concerns, please email aws-scm-ux@amazon.com.',
};

export default USER_DISPLAY_STRINGS;
